import moment from "moment";
import {CountryEnum, CurrencyEnum} from "../common/Country";
import {Money} from "../common/Money";

export enum PeriodEnum {
    YEARLY = "YEARLY",
    MONTHLY = "MONTHLY",
    ONLY_ONCE = "ONLY_ONCE"
}

export class MoneyChangeData {
    label!: string;
    money!: Money;
    endMonth?: string;
    changePeriod: PeriodEnum = PeriodEnum.YEARLY;
    changeMonth?: number = 1;
    isPassiveIncome?: boolean = false;
}

export class NetWorthInput {
    currentMonth!: string;
    assetList!: Money[];
    annualizedInterestOnWealthManagement!: number;
    incomeList!: MoneyChangeData[];
    expensesList!: MoneyChangeData[];
    netWorthGoal?: Money;
    destinationCurrency!: CurrencyEnum;
}

export class NetWorthAnalysis {
    monthlyNetWorth!: number;
    bankruptcyTime?: string;
    netWorthChange!: NetWorthChange;
}

export class NetWorthChange {
    summaryKeys!: string[];
    incomeKeys!: string[];
    expenseKeys!: string[];
    yearMonthKeys!: string[];
    assetAnalysis!: Map<AnalysisType, Map<AssetType, Map<string, number[]>>>;
}

export enum AnalysisType {
    ABSOLUTE = "ABSOLUTE",
    RELATIVE = "RELATIVE"
}

export enum AssetType {
    ASSET = "ASSET",
    INCOME = "INCOME",
    EXPENSE = "EXPENSE"
}

export const INVESTMENT_INCOME = 'investment income'

export class NetWorthCalculator {
    netWorthInput!: NetWorthInput;
    netWorthAnalysis!: NetWorthAnalysis;
}

export function initNetWorthCalculator() {
    let netWorthCalculator = new NetWorthCalculator();
    netWorthCalculator.netWorthInput = {
        annualizedInterestOnWealthManagement: 0,
        assetList: [],
        currentMonth: moment().format('YYYY-MM'),
        expensesList: [],
        incomeList: [{
            label: 'Salary',
            money: {
                amount: 0,
                currency: CurrencyEnum.CNY
            }, changePeriod: PeriodEnum.MONTHLY,
            changeMonth: 1
        }],
        destinationCurrency: CurrencyEnum.CNY

    };
    netWorthCalculator.netWorthAnalysis = {
        netWorthChange: {
            summaryKeys: [],
            incomeKeys: [],
            expenseKeys: [],
            yearMonthKeys: [],
            assetAnalysis: new Map()
        },
        monthlyNetWorth: 0
    };
    return netWorthCalculator;
}


export function initMoneyChangeData(): MoneyChangeData {
    return {
        label: '',
        changeMonth: 0,
        changePeriod: PeriodEnum.MONTHLY,
        money: {
            amount: 0,
            currency: CurrencyEnum.CNY
        }
    }
}

export function initAssetMap(list: MoneyChangeData[], exKeys: string[]) {
    let keys = []
    let map = new Map<string, number[]>();
    for (let exKey of exKeys) {
        if (keys.indexOf(exKey) === -1) {
            keys.push(exKey)
            map.set(exKey, [])
        }
    }
    for (let moneyChangeDatum of list) {
        if (keys.indexOf(moneyChangeDatum.label) === -1) {
            keys.push(moneyChangeDatum.label)
            map.set(moneyChangeDatum.label, [])
        }
    }
    return map
}

function initMap(netWorthInput: NetWorthInput) {

    let incomeMap = initAssetMap(netWorthInput.incomeList, [INVESTMENT_INCOME])
    let expenseMap = initAssetMap(netWorthInput.expensesList, [])

    let assetMap = new Map<string, number[]>()
    for (let key of Object.keys(AssetType)) {
        assetMap.set(key, [])
    }
    let map: Map<AssetType, Map<string, number[]>> = new Map()
    map.set(AssetType.ASSET, assetMap);
    map.set(AssetType.INCOME, incomeMap);
    map.set(AssetType.EXPENSE, expenseMap);
    return map;
}

export function initNetWorthChange(netWorthInput: NetWorthInput): NetWorthChange {
    let yearMonthKeys: string[] = []
    let summaryKeys: string[] = Object.keys(AssetType)
    let relative_map = initMap(netWorthInput);
    let incomeKeys: string[] = Array.from(relative_map.get(AssetType.INCOME)!.keys())
    let expenseKeys: string[] = Array.from(relative_map.get(AssetType.EXPENSE)!.keys())
    let absolute_map = initMap(netWorthInput);
    let assetAnalysisMap = new Map<AnalysisType, Map<AssetType, Map<string, number[]>>>()
    assetAnalysisMap.set(AnalysisType.RELATIVE, relative_map);
    assetAnalysisMap.set(AnalysisType.ABSOLUTE, absolute_map);
    console.log('debug here', assetAnalysisMap, yearMonthKeys,JSON.stringify(assetAnalysisMap))
    return {
        summaryKeys: summaryKeys,
        incomeKeys: incomeKeys,
        expenseKeys: expenseKeys,
        yearMonthKeys: yearMonthKeys,
        assetAnalysis: assetAnalysisMap
    }
}