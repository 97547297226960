import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {Card, Dropdown, Input, InputNumber, Menu, Popover, Select, Space, Table, Tooltip} from "antd";
import {CountryConfig, CountryEnum, CurrencyEnum} from "../common/Country";
import {IncomeTax} from "../model/IncomeTaxComparison";
import {ApplicationState} from "../reducers/reducer";
import {CHANGE_COUNTRY, CHANGE_INCOME_TAX, LOAD_EXCHANGE_RATIO} from "../actions/action";
import {calcCompareValue} from "./CompareNumber";
import {QuestionCircleOutlined} from '@ant-design/icons';
import {TaxRateConfig} from "../common/Tax";
import {Link} from "react-router-dom";

export function IncomeTaxView(index: number) {
    const {t, i18n} = useTranslation();
    const compareList: IncomeTax[] = useSelector((state: ApplicationState) => state.incomeTaxComparison.compareList);
    const incomeTax: IncomeTax = compareList[index];
    let taxRateConfig: TaxRateConfig = CountryConfig.get(incomeTax.country)!.taxRateConfig
    const dispatch = useDispatch()
    console.log(incomeTax)
    console.log(dispatch)
    useEffect(() => {
        dispatch({type: LOAD_EXCHANGE_RATIO, currency: incomeTax.currency})
    }, []);

    function buildTaxRateContent(taxRateConfig: TaxRateConfig, currency: CurrencyEnum) {
        let columns = [
            {
                title: `From (${currency})`,
                dataIndex: 'from',
                key: 'from',
            },
            {
                title: `To (${currency})`,
                dataIndex: 'to',
                key: 'to',
            },
            {
                title: t('Individual Income Tax rates'),
                dataIndex: 'rate',
                key: 'rate',
            },
        ];
        let taxRateList = taxRateConfig.taxRateList.map((value, index) => {
            return {
                from: index == 0 ? 0 : taxRateConfig.taxRateList[index - 1].money,
                to: value.money === Number.MAX_SAFE_INTEGER ? '∞' : value.money,
                rate: (value.rate * 100).toFixed(2) + '%'
            }
        })
        return <div>
            {taxRateConfig.taxLink ?
                <a target='_blank' href={taxRateConfig.taxLink}
                   rel={"noopener noreferrer"}>{t('Individual Income Tax rates')}</a> : t('Individual Income Tax rates')}
            <Table dataSource={taxRateList} columns={columns} pagination={false} bordered={true} size={"small"}/>
            <p>({t("Tax-free amount")} : {taxRateConfig.taxFreeAmount} {currency})</p>
        </div>;
    }

    return (

        <Card style={{
            width: '100%',
        }}
        >
            <Space size={"small"} direction={"vertical"}>
                <div>
                    <Space size={"small"} direction={"horizontal"}>
                        <span>{t("Country")}:</span>
                        <Select
                            defaultValue={CountryEnum.CN}
                            style={{
                                width: 120,
                            }}
                            value={incomeTax.country}
                            onChange={(e: any) => {
                                dispatch({
                                    type: CHANGE_COUNTRY,
                                    index: index,
                                    country: e
                                })
                            }}
                        >
                            {Array.from(CountryConfig.entries()).map(
                                ([key, value]) => {
                                    return <Select.Option id={key} value={key}>{key}<span
                                        className={`fi fi-${value.code}`}/></Select.Option>;
                                }
                            )}
                        </Select>

                        <Popover placement="bottom" title={'Tax Rate'}
                                 content={buildTaxRateContent(taxRateConfig, incomeTax.currency)}
                                 trigger="hover">
                            <QuestionCircleOutlined/>
                        </Popover>
                    </Space>
                </div>

                <div>
                    <Space>
                        <InputNumber
                            value={incomeTax.totalCompensation}
                            addonBefore={t("Pre-Tax Income")}
                            addonAfter={incomeTax.currency} onChange={(number: any) => {
                            dispatch({
                                type: 'CHANGE_INCOME_TAX',
                                index: index,
                                incomeTaxChange: {totalCompensation: number}
                            })
                        }}/>
                        <Popover placement="bottom"
                                 content={t("Pre-Tax Income Desc")}
                                 trigger="hover">
                            <QuestionCircleOutlined/>
                        </Popover>
                    </Space>
                </div>
                <div>
                    <Space>
                        <InputNumber
                            value={incomeTax.additionalDeductionAmount}
                            addonBefore={t("Additional Deduction Amount")}
                            addonAfter={incomeTax.currency} onChange={(number: any) => {
                            dispatch({
                                type: 'CHANGE_INCOME_TAX',
                                index: index,
                                incomeTaxChange: {additionalDeductionAmount: number}
                            })
                        }}/>
                        <Popover placement="bottom"
                                 content={t("Additional Deduction Amount Desc")}
                                 trigger="hover">
                            <QuestionCircleOutlined/>
                        </Popover>
                    </Space>
                </div>
                <div>
                    <Space>
                        <InputNumber
                            value={incomeTax.taxFreeIncome}
                            addonBefore={t("Tax-Free Income")}
                            addonAfter={incomeTax.currency} onChange={(number: any) => {
                            dispatch({
                                type: 'CHANGE_INCOME_TAX',
                                index: index,
                                incomeTaxChange: {taxFreeIncome: number}
                            })
                        }}/>
                        <Popover placement="bottom"
                                 content={t("Tax-Free Income Desc")}
                                 trigger="hover">
                            <QuestionCircleOutlined/>
                        </Popover>
                    </Space>
                </div>
                <div>
                    <InputNumber addonBefore={t("Public Holidays")}
                                 value={incomeTax.publicHolidays} addonAfter={t('Days')}/>
                </div>
                <div>
                    <InputNumber addonBefore={t("Annual Leave")}
                                 value={incomeTax.annualVacation} addonAfter={t('Days')}
                                 onChange={(number: any) => {
                                     dispatch({
                                         type: 'CHANGE_INCOME_TAX',
                                         index: index,
                                         incomeTaxChange: {annualVacation: number}
                                     })
                                 }}/>
                </div>
                <div>
                    <InputNumber addonBefore={t("Weekly Working Days")}
                        // width={'50px'}
                                 value={incomeTax.workDaysPerWeek} addonAfter={t('Days')}
                                 onChange={(number: any) => {
                                     dispatch({
                                         type: 'CHANGE_INCOME_TAX',
                                         index: index,
                                         incomeTaxChange: {workDaysPerWeek: number}
                                     })
                                 }}/>
                </div>
                <div>
                    <InputNumber addonBefore={t("Daily Working Hours")}
                        // width={'50px'}
                                 value={incomeTax.workHours} addonAfter={t('Hours')}
                                 onChange={(number: any) => {
                                     dispatch({
                                         type: 'CHANGE_INCOME_TAX',
                                         index: index,
                                         incomeTaxChange: {workHours: number}
                                     })
                                 }}/>
                </div>
            </Space>
        </Card>


    );
}