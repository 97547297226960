// ./actions/index.js

import {CurrencyEnum} from "../common/Country";
import {CompareModel} from "../components/CompareNumber";

export const ChangeCompareCurrency = 'ChangeCompareCurrency';

export const LOAD_EXCHANGE_RATIO = 'LOAD_EXCHANGE_RATIO';
export const CHANGE_INCOME_TAX = 'CHANGE_INCOME_TAX';
export const CHANGE_COUNTRY = 'CHANGE_COUNTRY';
export const CHANGE_NET_WORTH_INPUT = 'CHANGE_NET_WORTH_INPUT';
export const CALC_NET_WORTH = 'CALC_NET_WORTH';

export const UPDATE_COMPARE_CURRENCY = 'UPDATE_COMPARE_CURRENCY';
export const UPDATE_EXCHANGE_RATIO_MAP = 'UPDATE_EXCHANGE_RATIO_MAP';
export const UPDATE_COMPARE_MODEL = 'UpdateCompareModel';
export const UPDATE_NET_WORTH = 'UPDATE_NET_WORTH';

export function changeCompareCurrency(currency: CurrencyEnum) {
    return {
        type: ChangeCompareCurrency,
        currency: currency
    };
}

export function updateCompareModel(compareModel: CompareModel) {
    return {
        type: UPDATE_COMPARE_MODEL,
        compareModel: compareModel
    };
}

