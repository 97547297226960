import {CountryConfig, CountryEnum, CurrencyEnum} from "../common/Country";
import {CompareModel} from "../components/CompareNumber";

export class IncomeTaxComparison {
    compareList!: IncomeTax[]
    compareCurrency!: CurrencyEnum
    compareModel!: CompareModel
}

export class IncomeTax {
    // input
    country!: CountryEnum;
    totalCompensation!: number;
    additionalDeductionAmount!: number;
    taxFreeIncome!: number;
    currency!: CurrencyEnum;
    publicHolidays!: number;
    workDaysPerWeek!: number;
    workHours!: number;
    annualVacation!: number;

    // output
    // after-tax income
    income!: number;
    tax!: number;
    // after-tax income + tax free income
    allIncome!:number;
    dailyIncome!: number;
    hourlyIncome!: number;


}

function initTaxIncome(countryEnum: CountryEnum) {
    let countryConfig = CountryConfig.get(countryEnum)!;
    let taxIncome: IncomeTax = {
        annualVacation: countryConfig.annualVacation,
        country: countryEnum,
        currency: countryConfig.currency,
        additionalDeductionAmount: 0,
        taxFreeIncome: 0,
        allIncome:0,
        dailyIncome: 0,
        hourlyIncome: 0,
        income: 0,
        publicHolidays: countryConfig.publicHolidays,
        tax: 0,
        totalCompensation: 0,
        workDaysPerWeek: countryConfig.workDaysPerWeek,
        workHours: countryConfig.workHours
    }
    return taxIncome;
}

export function initIncomeTaxComparison(): IncomeTaxComparison {
    return {
        compareList: [initTaxIncome(CountryEnum.CN),
            initTaxIncome(CountryEnum.SG),],
        compareCurrency: CurrencyEnum.CNY,
        compareModel: CompareModel.PERCENTAGE
    }
}