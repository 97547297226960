import {put, takeEvery} from "redux-saga/effects";
import {CALC_NET_WORTH, CHANGE_NET_WORTH_INPUT, UPDATE_NET_WORTH} from "../actions/action";
import {CurrencyEnum} from "../common/Country";
import {
    AnalysisType,
    AssetType,
    initNetWorthChange,
    INVESTMENT_INCOME,
    MoneyChangeData,
    NetWorthCalculator,
    NetWorthChange,
    NetWorthInput,
    PeriodEnum
} from "../model/NetWorthCalculator";
import {stringfy} from "../common/JsonUtil";

export function* updateNetWorthInput(action: any) {
    // let netWorthCalculator: NetWorthCalculator = {
    //     netWorthAnalysis: {
    //         monthlyNetWorth: 0,
    //         bankruptcyTime: '',
    //     }, netWorthInput: action.netWorthInput
    // }
    //
    // console.log("debug netWorthCalculator", netWorthCalculator)
    // yield put({type: UPDATE_NET_WORTH, netWorthCalculator: netWorthCalculator});
}

export function* calcNetWorth(action: any) {
    // @ts-ignore
    // let netWorthInput: NetWorthInput = {}
    // let yearMonth = netWorthInput.currentMonth.split('-')
    // let nowYear = parseInt(yearMonth[0], 10)
    // let nowMonth = parseInt(yearMonth[1], 10)
    // let monthInterest = Math.pow(1 + netWorthInput.annualizedInterestOnWealthManagement, 1 / 12) - 1
    // let ratio: any = new Map<CurrencyEnum, Map<CurrencyEnum, number>>()
    // ratio.set(CurrencyEnum.SGD, new Map<CurrencyEnum, number>().set(CurrencyEnum.CNY, 5))
    // ratio.set(CurrencyEnum.USD, new Map<CurrencyEnum, number>().set(CurrencyEnum.CNY, 7))
    // ratio.set(CurrencyEnum.CNY, new Map<CurrencyEnum, number>().set(CurrencyEnum.CNY, 1))
    //
    // let netWorthChange: NetWorthChange = initNetWorthChange(netWorthInput)
    // // console.log('debugaaabb', netWorthChange)
    // let initAsset = 0
    // netWorthInput.assetList.forEach((asset) => {
    //     initAsset += asset.amount * ratio.get(asset.currency).get(netWorthInput.destinationCurrency)
    // })
    // let init = true;
    // while (nowYear <= 2030) {
    //     appendMoneyChange(netWorthChange, nowYear, nowMonth, initAsset, monthInterest, netWorthInput, ratio, init)
    //     init = false
    //     nowMonth += 1
    //     if (nowMonth > 12) {
    //         nowMonth = 1
    //         nowYear += 1
    //     }
    // }
    // // console.log('debug new', netWorthChange, stringfy(netWorthChange))
    // let netWorthCalculator: NetWorthCalculator = {
    //     netWorthAnalysis: {
    //         monthlyNetWorth: 1,
    //         bankruptcyTime: 'string',
    //         netWorthChange: netWorthChange
    //     },
    //     netWorthInput: netWorthInput
    // }
    // yield put({type: UPDATE_NET_WORTH, netWorthCalculator: netWorthCalculator})
}

function appendMoneyChange(netWorthChange: NetWorthChange, nowYear: number, nowMonth: number,
                           initAsset: number, monthInterest: number,
                           netWorthInput: NetWorthInput, ratio: any,
                           init: boolean) {
    let assetNewWorthList = netWorthChange.assetAnalysis!.get(AnalysisType.ABSOLUTE)!.get(AssetType.ASSET)!.get(AssetType.ASSET)!
    let currentAsset = init ? initAsset : assetNewWorthList[assetNewWorthList.length - 1]
    console.log('appOneMonth', currentAsset, monthInterest)
    let currentInvestmentIncome = (currentAsset * monthInterest)
    let incomeChange = calcAssetChangeMap(netWorthInput.incomeList, nowMonth, ratio, netWorthInput.destinationCurrency)
    incomeChange.set(INVESTMENT_INCOME, currentInvestmentIncome)
    let expenseChange = calcAssetChangeMap(netWorthInput.expensesList, nowMonth, ratio, netWorthInput.destinationCurrency)
    netWorthChange.yearMonthKeys!.push(nowYear + '-' + ("0" + nowMonth).slice(-2))
    appOneMonth(netWorthChange, AssetType.INCOME, netWorthChange.incomeKeys, incomeChange, init)
    appOneMonth(netWorthChange, AssetType.EXPENSE, netWorthChange.expenseKeys, expenseChange, init)
    let assetIncomeList = netWorthChange.assetAnalysis!.get(AnalysisType.ABSOLUTE)!.get(AssetType.ASSET)!.get(AssetType.INCOME)!
    let assetExpenseList = netWorthChange.assetAnalysis!.get(AnalysisType.ABSOLUTE)!.get(AssetType.ASSET)!.get(AssetType.EXPENSE)!

    assetNewWorthList.push(assetIncomeList[assetIncomeList.length - 1] - assetExpenseList[assetExpenseList.length - 1] + initAsset)
    let relativeIncomeList = netWorthChange.assetAnalysis!.get(AnalysisType.RELATIVE)!.get(AssetType.ASSET)!.get(AssetType.INCOME)!
    let relativeExpenseList = netWorthChange.assetAnalysis!.get(AnalysisType.RELATIVE)!.get(AssetType.ASSET)!.get(AssetType.EXPENSE)!
    let relativeNewWorthList = netWorthChange.assetAnalysis!.get(AnalysisType.RELATIVE)!.get(AssetType.ASSET)!.get(AssetType.ASSET)!
    relativeNewWorthList.push(relativeIncomeList[relativeIncomeList.length - 1] - relativeExpenseList[relativeExpenseList.length - 1])
    return assetNewWorthList[assetNewWorthList.length - 1];
}

function appOneMonth(netWorthChange: NetWorthChange, assetType: AssetType, keys: string[], changeMap: Map<string, number>, init: boolean) {
    let relativeMap: Map<string, number[]> = netWorthChange.assetAnalysis!.get(AnalysisType.RELATIVE)!.get(assetType)!
    let absoluteMap: Map<string, number[]> = netWorthChange.assetAnalysis!.get(AnalysisType.ABSOLUTE)!.get(assetType)!
    let totalAmount = 0;
    console.log('debug keys', keys)
    for (const key of keys) {
        console.log('debug relativeMap', relativeMap, key)
        let amount = changeMap.get(key) || 0;
        console.log('debug changeMap', changeMap, key, amount)
        totalAmount += amount;

        relativeMap.get(key)!.push(amount);
        let absoluteList = absoluteMap.get(key)!
        let lastAmount = (absoluteList.length > 0 ? absoluteList[absoluteList.length - 1] : 0)
        absoluteList.push(lastAmount + amount)
    }
    let assetRelativeMap: Map<string, number[]> = netWorthChange.assetAnalysis!.get(AnalysisType.RELATIVE)!.get(AssetType.ASSET)!
    let assetRelativeList = assetRelativeMap.get(assetType)!
    assetRelativeList.push(totalAmount)
    let assetAbsoluteMap: Map<string, number[]> = netWorthChange.assetAnalysis!.get(AnalysisType.ABSOLUTE)!.get(AssetType.ASSET)!
    let assetAbsoluteList = assetAbsoluteMap.get(assetType)!
    let lastAmount = (assetAbsoluteList.length > 0 ? assetAbsoluteList[assetAbsoluteList.length - 1] : 0)
    assetAbsoluteList.push(lastAmount + totalAmount);
    console.log('appOneMonth', stringfy(netWorthChange))
}


function calcAssetChangeMap(moneyChangeDataList: MoneyChangeData[], nowMonth: number, ratio: any, destinationCurrency: CurrencyEnum) {
    let assetChangeMap = new Map<string, number>()
    moneyChangeDataList.forEach((moneyChangeData: MoneyChangeData) => {
        let currentAmount: number = assetChangeMap.get(moneyChangeData.label) || 0
        if (moneyChangeData.changePeriod === PeriodEnum.MONTHLY) {
            currentAmount += moneyChangeData.money.amount * ratio.get(moneyChangeData.money.currency).get(destinationCurrency)
        } else if (moneyChangeData.changePeriod === PeriodEnum.YEARLY) {
            if (nowMonth === moneyChangeData.changeMonth) {
                currentAmount += moneyChangeData.money.amount * ratio.get(moneyChangeData.money.currency).get(destinationCurrency)
            }
        }
        assetChangeMap.set(moneyChangeData.label, currentAmount);
    })
    return assetChangeMap;
}


export function* netWorthSagas() {
    yield takeEvery(CHANGE_NET_WORTH_INPUT, updateNetWorthInput);
    yield takeEvery(CALC_NET_WORTH, calcNetWorth);
}
