import {IncomeTax} from "../model/IncomeTaxComparison";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../reducers/reducer";
import React, {useEffect} from "react";
import {Card, InputNumber, Popover, Select, Space} from "antd";
import {CountryConfig, CountryEnum, CurrencyEnum} from "../common/Country";
import {calcIncreasingValue, CompareModel} from "./CompareNumber";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

function getRatio(moneyChangeRatioMap: Map<CurrencyEnum, Map<CurrencyEnum, number>>, baseCurrency: CurrencyEnum, toCurrency: CurrencyEnum) {
    let ratioMap = moneyChangeRatioMap.get(baseCurrency);
    if (ratioMap != null) {
        return ratioMap.get(toCurrency) || 0;
    }
    return 0;
}

export function IncomeTaxCompareView(index: number) {
    const {t, i18n} = useTranslation();
    let state = useSelector((state: ApplicationState) => state);
    let incomeTaxComparison = state.incomeTaxComparison;
    let compareModel: CompareModel = incomeTaxComparison.compareModel;
    const compareList: IncomeTax[] = state.incomeTaxComparison.compareList;
    const incomeTax: IncomeTax = compareList[index];
    const base: IncomeTax = index != 0 ? compareList[0] : compareList[index];
    const compareCurrency = incomeTaxComparison.compareCurrency;
    let incomeTaxRatio: number = getRatio(state.moneyChangeRatioMap, incomeTax.currency, compareCurrency);
    let baseRatio: number = getRatio(state.moneyChangeRatioMap, base.currency, compareCurrency);
    let dispatch = useDispatch()
    console.log(dispatch)
    useEffect(() => {

    }, []);
    return (
        <Card style={{
            width: '100%',
        }}
        >
            <Space size={"small"} direction={"vertical"}>
                <div>
                    <Space>
                        <InputNumber addonBefore={t("All Income")} precision={2}
                                     disabled={true} // width={'80px'}
                                     value={calcIncreasingValue(incomeTax.allIncome * incomeTaxRatio, base.allIncome * baseRatio, compareModel)}
                                     addonAfter={compareCurrency}/>
                        <Popover placement="bottom"
                                 content={`[${t("After-Tax Income")}] + [${t("Tax-Free Income")}]`}
                                 trigger="hover">
                            <QuestionCircleOutlined/>
                        </Popover>
                    </Space>
                </div>

                <div>
                    <Space>
                        <InputNumber addonBefore={t("Daily Income")} precision={2}
                                     disabled={true} // width={'80px'}
                                     value={calcIncreasingValue(incomeTax.dailyIncome * incomeTaxRatio, base.dailyIncome * baseRatio, compareModel)}
                                     addonAfter={compareCurrency}/>
                        <Popover placement="bottom"
                                 content={`${t("All Income")} / ${t("Actual Working Days")}`}
                                 trigger="hover">
                            <QuestionCircleOutlined/>
                        </Popover>
                    </Space>
                </div>
                <div>
                    <Space>
                        <InputNumber addonBefore={t("Hourly Income")} precision={2}
                                     disabled={true} // width={'80px'}
                                     value={calcIncreasingValue(incomeTax.hourlyIncome * incomeTaxRatio, base.hourlyIncome * baseRatio, compareModel)}
                                     addonAfter={compareCurrency}/>
                        <Popover placement="bottom"
                                 content={`${t("All Income")} / ${t("Actual Working Hours")}`}
                                 trigger="hover">
                            <QuestionCircleOutlined/>
                        </Popover>
                    </Space>
                </div>
                <div>
                    <InputNumber
                        value={calcIncreasingValue(incomeTax.totalCompensation * incomeTaxRatio, base.totalCompensation * baseRatio, compareModel)}
                        addonBefore={t("Pre-Tax Income")}
                        precision={2}
                        disabled={true}
                        addonAfter={compareCurrency}/>
                </div>
                <div>

                    <InputNumber addonBefore={t("After-Tax Income")} precision={2}
                                 disabled={true} // width={'80px'}
                                 value={calcIncreasingValue(incomeTax.income * incomeTaxRatio, base.income * baseRatio, compareModel)}
                                 addonAfter={compareCurrency}/>
                </div>
                <div>

                    <InputNumber addonBefore={t("Tax")} precision={2}
                                 disabled={true} // width={'80px'}
                                 value={calcIncreasingValue(incomeTax.tax * incomeTaxRatio, base.tax * baseRatio, compareModel)}
                                 addonAfter={compareCurrency}/>
                </div>

            </Space>


        </Card>

    );
}