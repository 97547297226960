import {Menu, MenuProps} from "antd";
import {CalculatorTwoTone, MoneyCollectTwoTone, SettingOutlined} from '@ant-design/icons';
import React from "react";

const items: MenuProps['items'] = [
    {
        label: <a href="/calc">
            TaxCalculator
        </a>,
        key: 'taxCalc',
        icon: <CalculatorTwoTone/>,
    },
    {
        label: <a href="/net_worth">
            NetWorthCalculator
        </a>,
        key: 'netWorthCalc',
        icon: <MoneyCollectTwoTone/>
    },
];


export function MenuView(selectedKey: string | null = null) {
    return <Menu
        mode="horizontal"
        selectedKeys={selectedKey ? [selectedKey] : []}
        defaultOpenKeys={['sub1']}
        items={items}
    />
}