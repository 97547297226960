import {Country, CountryConfig, CountryEnum, CurrencyEnum} from "../common/Country";
import {TaxRateConfig} from "../common/Tax";
import {IncomeTax} from "../model/IncomeTaxComparison";
import {ONE_YEAR} from "../common/Days";


// use this link to debug china TAX https://www.gerensuodeshui.cn/
let calcIncomeAndTax = function (incomeTax: IncomeTax): IncomeTax {
    let country = incomeTax.country;
    let totalIncome = incomeTax.totalCompensation;
    let countryConfig: Country | undefined = CountryConfig.get(country);
    if (!countryConfig) {
        throw new Error(`Country ${country} is not supported`);
    }
    let taxRateConfig: TaxRateConfig = countryConfig.taxRateConfig;
    let totalIncomeNeedTax = Math.max(0, totalIncome - taxRateConfig.taxFreeAmount - incomeTax.additionalDeductionAmount);
    let pre = 0;
    let totalTax = 0;
    for (let taxRate of countryConfig.taxRateConfig.taxRateList) {
        let money = taxRate.money
        let rate = taxRate.rate
        let taxIncome = Math.min(Math.max(0, totalIncomeNeedTax - pre), money - pre);
        if (taxIncome === 0) {
            break;
        }
        totalTax += (taxIncome * rate);
        pre = money;
    }
    let taxedIncome = totalIncome - totalTax;
    let allIncome = taxedIncome + incomeTax.taxFreeIncome;
    let workDays = (ONE_YEAR - incomeTax.publicHolidays - incomeTax.annualVacation) * incomeTax.workDaysPerWeek / 7;
    let dailyWage = allIncome / workDays;
    let hourlyWage = dailyWage / incomeTax.workHours;
    return {
        country: country,
        totalCompensation: totalIncome,
        tax: totalTax,
        income: taxedIncome,
        additionalDeductionAmount: incomeTax.additionalDeductionAmount,
        taxFreeIncome: incomeTax.taxFreeIncome,
        currency: countryConfig.currency,
        allIncome: allIncome,
        dailyIncome: dailyWage,
        hourlyIncome: hourlyWage,
        publicHolidays: incomeTax.publicHolidays,
        workDaysPerWeek: incomeTax.workDaysPerWeek,
        workHours: incomeTax.workHours,
        annualVacation: incomeTax.annualVacation
    }
}

export {calcIncomeAndTax}