import {Input} from "antd";
import React from "react";

// export function IncomeTaxView(value: number, baseValue: number) {
//     return <Input addonBefore={"Tax"}
//                   disabled={true} width={'50px'}
//                   value={calcCompareValue(value, baseValue)}
//                   addonAfter={incomeTax.currency}/>
// }
export enum CompareModel {
    ABSOLUTE = 'ABSOLUTE',
    PERCENTAGE = 'PERCENTAGE'
}

export function calcCompareValue(value: number, baseValue: number) {
    if (invalidValueCheck(value)) {
        return '';
    }
    if (baseValue === 0) {
        return value;
    }
    const valueStr = value.toFixed(2);
    if (invalidValueCheck(baseValue)) {
        return valueStr;
    }
    let ratio: number = value / baseValue;
    return `${valueStr}(${(ratio * 100.0).toFixed(2)}%)`
}

export function calcIncreasingValue(value: number, baseValue: number, compareModel: CompareModel) {
    if (invalidValueCheck(value)) {
        return '';
    }
    if (baseValue === 0) {
        return value;
    }
    const valueStr = value.toFixed(2);
    if (invalidValueCheck(baseValue)) {
        return valueStr;
    }
    if (value === baseValue) {
        return valueStr;
    }
    switch (compareModel) {
        case CompareModel.ABSOLUTE:
            let valueDiff: number = value - baseValue;
            return `${value.toFixed(2)}(${(valueDiff >= 0 ? '+' : '') + (valueDiff).toFixed(0)})`;
        case CompareModel.PERCENTAGE:
            let increasedRatio: number = value / baseValue - 1.0;
            return `${value.toFixed(2)}(${(increasedRatio >= 0 ? '+' : '') + (increasedRatio * 100.0).toFixed(2)}%)`;
        default:
            return valueStr;
    }
}

function invalidValueCheck(baseValue: number) {
    return (baseValue == null || Number.isNaN(baseValue));

}

export function toFixed(value: number, precision: number) {
    if (value == null) {
        return '';
    }
    return value.toFixed(precision)
}
