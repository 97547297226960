import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';

import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux';
import {applyMiddleware, legacy_createStore as createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import reducer from './reducers/reducer';
import rootSaga from './sagas/saga';
import {TaxCalcView} from "./view/TaxCalcView";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {NetWorthCalcView} from "./view/NetWorthCalcView";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: {
                    "Welcome to React": "Welcome to React and react-i18next",
                    "TaxCalculator": "Individual Income Tax Calculator",
                    "TaxCalculatorDesc": "This is a individual income tax calculator for comparing offers in different countries.",
                    "Country": "Country",
                    "Pre-Tax Income": "Annual Pre-Tax Income",
                    "Pre-Tax Income Desc": "Should not include the personal part of social security, medical insurance and provident fund",
                    "Additional Deduction Amount": "Additional Deduction Amount",
                    "Additional Deduction Amount Desc": "The amount according to the personal income tax deduction policy",
                    "Tax-Free Income Desc": "The part you think it counts as income.(Such as social insurance, medical insurance, and provident fund paid by companies and individuals",
                    "Tax-Free Income": "Annual Tax-Free Income",
                    "Public Holidays": "Public Holidays",
                    "Annual Leave": "Annual Leave",
                    "Weekly Working Days": "Weekly Working Days",
                    "Daily Working Hours": "Daily Working Hours",
                    "Days": "days",
                    "Hours": "hours",
                    "Compare Currency": "Compare Currency",
                    "Compare Model": "Compare Model",
                    "All Income": "All Income",
                    "Daily Income": "Daily Income",
                    "Hourly Income": "Hourly Income",
                    "After-Tax Income": "Annual After-Tax Income",
                    "Tax": "Tax",
                    "CNY": "CNY",
                    "SGD": "SGD",
                    "USD": "USD",
                    "PERCENTAGE": "Percentage",
                    "ABSOLUTE": "Absolute",
                    "Actual Working Days": "Actual Working Days",
                    "Actual Working Hours": "Actual Working Hours",
                    "Individual Income Tax rates": "Individual Income Tax rates",
                    "Tax-free amount": "Tax-free amount"
                }
            },
            cn: {
                translation: {
                    "Welcome to React": "欢迎来到react",
                    "TaxCalculator": "个人所得税计算器",
                    "TaxCalculatorDesc": "这是一个个人所得税计算器，用于比较不同国家的Offer。",
                    "Country": "国家",
                    "Pre-Tax Income": "全年应税收入",
                    "Additional Deduction Amount": "税收减免额度",
                    "Tax-Free Income": "全年免税收入",
                    "Pre-Tax Income Desc": "应税金额,不应该包括社保、医疗保险和公积金的个人部分",
                    "Additional Deduction Amount Desc": "按个人所得税减免政策的金额,如赡养老人、租房房贷减免",
                    "Tax-Free Income Desc": "认为可以算作收入的福利。（如公司和个人缴纳的社会保险、医疗保险、公积金等)",
                    "Public Holidays": "公共假期",
                    "Annual Leave": "年假",
                    "Weekly Working Days": "工作天数/周",
                    "Daily Working Hours": "工作小时/日",
                    "Days": "天",
                    "Hours": "小时",
                    "Compare Currency": "对比货币",
                    "Compare Model": "对比模型",
                    "All Income": "所有收入",
                    "Daily Income": "每日收入",
                    "Hourly Income": "每小时收入",
                    "After-Tax Income": "全年税后收入",
                    "Tax": "个人所得税",
                    "CNY": "人民币",
                    "SGD": "新加坡元",
                    "USD": "美元",
                    "PERCENTAGE": "百分比",
                    "ABSOLUTE": "绝对值",
                    "Actual Working Days": "实际工作天数",
                    "Actual Working Hours": "实际工作小时数",
                    "Individual Income Tax rates": "个人所得税税率",
                    "Tax-free amount": "个人所得税减免额"
                }
            }
        },
        lng: "en", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });


const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

const rootElement: Element = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<TaxCalcView/>}/>
                <Route path="/calc" element={<TaxCalcView/>}/>
                <Route path="/net_worth" element={<NetWorthCalcView/>}/>
            </Routes>
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
