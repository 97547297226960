import {all, call, put, takeEvery, select} from 'redux-saga/effects';
import {
    CHANGE_COUNTRY,
    CHANGE_INCOME_TAX,
    ChangeCompareCurrency,
    LOAD_EXCHANGE_RATIO,
    UPDATE_COMPARE_CURRENCY,
    UPDATE_EXCHANGE_RATIO_MAP
} from "../actions/action";
import FetchUtil from "../utils/FetchUtil";
import {IncomeTax} from "../model/IncomeTaxComparison";
import {calcIncomeAndTax} from "../logic/TaxCalculator";
import {ApplicationState} from "../reducers/reducer";
import {Country, CountryConfig, CountryEnum, CurrencyEnum} from "../common/Country";
import {netWorthSagas} from "./NetWorthSaga";

export function* changeCompareCurrency(action: any) {
    let currency = action.currency;
    console.log("fetchToDoList", currency)
    yield put({type: UPDATE_COMPARE_CURRENCY, compareCurrency: currency});
    // @ts-ignore
    const response = yield call(fetch, "https://v6.exchangerate-api.com/v6/3b16e4d52611409055245fbf/latest/" + currency);
    // @ts-ignore
    const responseBody = yield response.json(); // HERE is what you want


    // let response : any = yield call(FetchUtil.fetchGet, );
    console.log('response.conversion_rates', responseBody)
    console.log('response.conversion_rates', responseBody.conversion_rates)
    yield put({
        type: UPDATE_EXCHANGE_RATIO_MAP,
        exchangeKey: currency,
        exchangeMap: new Map(Object.entries(responseBody.conversion_rates))
    });

}

export function* loadExchangeRate(action: any) {
    const state: ApplicationState = yield select(item => item);
    let currency = action.currency;
    if (state.moneyChangeRatioMap.get(currency) != null) {
        return;
    }
    try {
        // @ts-ignore
        const response = yield call(fetch, "/api/exchangerate?currency=" + currency);
        // @ts-ignore
        const responseBody = yield response.json(); // HERE is what you want
        // let response : any = yield call(FetchUtil.fetchGet, );
        console.log('response.conversion_rates', responseBody)
        console.log('response.conversion_rates', responseBody.rate)
        yield put({
            type: UPDATE_EXCHANGE_RATIO_MAP,
            exchangeKey: currency,
            exchangeMap: new Map(Object.entries(responseBody.rate))
        });
    } catch (Exception) {
        console.log('Exception', Exception)
    }
}

export function* changeInComeTax(action: any) {
    const state: ApplicationState = yield select(item => item);
    console.log('CHANGE_INCOME_TAX here here ', action)
    let incomeTax: IncomeTax = structuredClone(state.incomeTaxComparison.compareList[action.index]);
    let incomeTaxChange: IncomeTax = action.incomeTaxChange;
    let country = incomeTaxChange.country != null ? incomeTaxChange.country : incomeTax.country;
    let totalCompensation = incomeTaxChange.totalCompensation != null ? incomeTaxChange.totalCompensation : incomeTax.totalCompensation;
    incomeTax.country = country;
    incomeTax.totalCompensation = totalCompensation;
    incomeTax.workDaysPerWeek = incomeTaxChange.workDaysPerWeek != null ? incomeTaxChange.workDaysPerWeek : incomeTax.workDaysPerWeek;
    incomeTax.workHours = incomeTaxChange.workHours != null ? incomeTaxChange.workHours : incomeTax.workHours;
    incomeTax.annualVacation = incomeTaxChange.annualVacation != null ? incomeTaxChange.annualVacation : incomeTax.annualVacation;
    incomeTax.taxFreeIncome = incomeTaxChange.taxFreeIncome != null ? incomeTaxChange.taxFreeIncome : incomeTax.taxFreeIncome;
    incomeTax.additionalDeductionAmount = incomeTaxChange.additionalDeductionAmount != null ? incomeTaxChange.additionalDeductionAmount : incomeTax.additionalDeductionAmount;
    let newIncomeTax = calcIncomeAndTax(incomeTax);
    console.log('incomeTax', incomeTax, 'newIncomeTax', JSON.stringify(newIncomeTax))
    yield put({
        type: 'UPDATE_INCOME_TAX',
        index: action.index,
        newIncomeTax: newIncomeTax
    });
}

export function* changeCountry(action: any) {
    let countryConfig: Country = CountryConfig.get(action.country)!;
    let incomeTax = {
        ...countryConfig,
        country: action.country,
        totalCompensation: 0,
        income: 0,
        tax: 0,
        dailyIncome: 0,
        hourlyIncome: 0,
        additionalDeductionAmount:0,
        taxFreeIncome:0
    }
    console.log('CHANGE_INCOME_TAX here here ', action)
    yield put({
        type: 'UPDATE_INCOME_TAX',
        index: action.index,
        newIncomeTax: incomeTax
    });
    yield call(loadExchangeRate, {currency: incomeTax.currency});
}


export function* taxCalcSagas() {
    yield takeEvery(ChangeCompareCurrency, changeCompareCurrency);
    yield takeEvery(LOAD_EXCHANGE_RATIO, loadExchangeRate);
    yield takeEvery(CHANGE_INCOME_TAX, changeInComeTax);
    yield takeEvery(CHANGE_COUNTRY, changeCountry);
}
