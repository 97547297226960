// ./src/sagas/index.js


import {taxCalcSagas} from "./TaxCalcSaga";
import {netWorthSagas} from "./NetWorthSaga";
import {all} from "redux-saga/effects";

export default function* rootSaga() {
    yield all([taxCalcSagas(), netWorthSagas()]);
}
