import {calcIncomeAndTax} from "../logic/TaxCalculator";
import {CountryConfig, CountryEnum, CurrencyEnum} from "../common/Country";
import {IncomeTax, IncomeTaxComparison, initIncomeTaxComparison} from "../model/IncomeTaxComparison";
import enUS from 'antd/lib/locale-provider/en_US';
import {
    UPDATE_COMPARE_CURRENCY,
    UPDATE_EXCHANGE_RATIO_MAP,
    UPDATE_COMPARE_MODEL,
    UPDATE_NET_WORTH
} from "../actions/action";
import {CompareModel} from "../components/CompareNumber";
import {initNetWorthCalculator, NetWorthCalculator} from "../model/NetWorthCalculator";
import {Locale} from "antd/lib/locale-provider";

export class ApplicationState {
    incomeTaxComparison!: IncomeTaxComparison;
    moneyChangeRatioMap!: Map<CurrencyEnum, Map<CurrencyEnum, number>>;
    netWorthCalculator!: NetWorthCalculator;
    // locale!: Locale
}

let init_state: ApplicationState = {
    incomeTaxComparison: initIncomeTaxComparison(),
    moneyChangeRatioMap: new Map<CurrencyEnum, Map<CurrencyEnum, number>>(),
    netWorthCalculator: initNetWorthCalculator(),
    // locale: enUS
}
export default function reducer(state = init_state, action: any) {
    switch (action.type) {
        case 'UPDATE_INCOME_TAX':
            console.log('UPDATE_INCOME_TAX here here ', state.incomeTaxComparison.compareList[action.index], action.newIncomeTax)
            state.incomeTaxComparison.compareList[action.index] = action.newIncomeTax;
            return {...state};
        case UPDATE_COMPARE_CURRENCY:
            console.log('uodate compare currency', action.compareCurrency)
            return {
                ...state,
                incomeTaxComparison: {
                    ...state.incomeTaxComparison,
                    compareCurrency: action.compareCurrency
                },
            };
        case UPDATE_EXCHANGE_RATIO_MAP:
            return {
                ...state,
                moneyChangeRatioMap: state.moneyChangeRatioMap.set(action.exchangeKey, action.exchangeMap)
            };
        case UPDATE_COMPARE_MODEL:
            return {
                ...state,
                incomeTaxComparison: {
                    ...state.incomeTaxComparison,
                    compareModel: action.compareModel
                },
            };
        case UPDATE_NET_WORTH:
            return {
                ...state,
                netWorthCalculator: action.netWorthCalculator
            };
        default:
            return state
    }
}
