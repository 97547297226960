import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Button, DatePicker, Form, FormListFieldData, Input, Space} from "antd";
import {useTranslation} from "react-i18next";
import "antd/dist/antd.css";
import {init} from "echarts";

import {CALC_NET_WORTH, CHANGE_NET_WORTH_INPUT} from "../actions/action";
import {ApplicationState} from "../reducers/reducer";
import {Helmet} from "react-helmet";
import {useSearchParams} from 'react-router-dom';
import {
    AnalysisType,
    AssetType,
    initMoneyChangeData,
    MoneyChangeData,
    NetWorthAnalysis,
    NetWorthCalculator,
    NetWorthChange,
    NetWorthInput
} from "../model/NetWorthCalculator";
import {MoneyNumber} from "../components/MoneyNumber";
import {Money} from "../common/Money";
import {MoneyChange} from "../components/MoneyChange";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {MenuView} from "./MenuView";
import {stringfy} from "../common/JsonUtil";

export function NetWorthCalcView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const state: any = useSelector((state: ApplicationState) => state)
    let netWorthCalculator: NetWorthCalculator = state.netWorthCalculator;
    let netWorthInput: NetWorthInput = netWorthCalculator.netWorthInput;
    let netWorthAnalysis = netWorthCalculator.netWorthAnalysis;
    componentDidMount(netWorthAnalysis)
    const [form] = Form.useForm();
    const formatter_default = function(params: any) {
        if(params instanceof Array) {
            if(params.length) {
                let message = '';
                message += `${ params[0].axisValueLabel }`;
                params.forEach(param => {
                    message += `<br/>${ param.marker }${ param.seriesName }: ${ param.value }${ param.data.unit || '' }`;
                });
                return message;
            } else {
                return null;
            }
        } else {
            let message = '';
            message += `${ params[0].axisValueLabel }`;
            message += `<br/>${ params.marker }${ params.seriesName }: ${ params.value }${ params.data.unit || '' }`;
            return message;
        }
    };


    function buildPieGraphOption(netWorthChange: NetWorthChange, analysisType: AnalysisType, x: number) {
        return {
            series: [{
                type: 'pie',
                id: 'pie',
                radius: '35%',
                center: ['35%', '25%'],
                emphasis: {
                    focus: 'self'
                },
                label: {
                    formatter: '{b}: {@[]} ({d}%)'
                },
                name: 'Access From',
                data: netWorthChange.incomeKeys.map((key, index) => {
                    return {
                        name: key,
                        value: netWorthChange.assetAnalysis.get(analysisType)!.get(AssetType.INCOME)!.get(key)![x],
                    }
                }).filter(item => item.value > 0)
            },
                {
                    type: 'pie',
                    id: 'expensePie',
                    radius: '35%',
                    center: ['65%', '25%'],
                    emphasis: {
                        focus: 'self'
                    },
                    label: {
                        formatter: '{b}: {@[]} ({d}%)'
                    },
                    data: netWorthChange.expenseKeys.map((key, index) => {
                        return {
                            name: key,
                            value: netWorthChange.assetAnalysis.get(analysisType)!.get(AssetType.EXPENSE)!.get(key)![x],
                        }
                    }).filter(item => item.value > 0)
                }
            ]
        }
    }

    function componentDidMount(netWorthAnalysis: NetWorthAnalysis) {
        console.log('debug render', stringfy(netWorthAnalysis))
        if (netWorthAnalysis.netWorthChange.summaryKeys.length == 0) {
            return
        }
        let analysisType = AnalysisType.ABSOLUTE
        let netWorthChange: NetWorthChange = netWorthAnalysis.netWorthChange
        let yearMonthKeys = netWorthChange.yearMonthKeys;
        var myChart = init(document.getElementById('main')!);
        myChart.on('updateAxisPointer', function (event: any) {
            const xAxisInfo = event.axesInfo[0];
            if (xAxisInfo) {
                const x = xAxisInfo.value
                console.log(x)
                myChart.setOption(buildPieGraphOption(netWorthChange, analysisType, x));
            }
        });
        myChart.setOption({
            title: {text: 'Fire计划'},
            tooltip: {
                trigger: 'axis',
                // formatter:formatter_default
                formatter: function (data:any) {
                    let message = data[0].axisValueLabel
                    data.forEach((param:any) => {
                        message += `<br/>${ param.marker }${ param.seriesName }: ${ param.value }${ param.data.unit || '' }`;
                    });
                    return message;
                }
            },
            legend: {
                data: [...netWorthChange.summaryKeys,
                    ...netWorthChange.incomeKeys,
                    ...netWorthChange.expenseKeys]
            },
            toolbox: {
                show: true,
                feature: {
                    dataView: {show: true, readOnly: false},
                    magicType: {show: true, type: ['line', 'bar']},
                    restore: {show: true},
                    saveAsImage: {
                        show: true,
                        type: 'jpg'
                    }
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                },
                {
                    start: 0,
                    end: 100
                }
            ],
            xAxis: [
                {
                    type: 'category',
                    data: yearMonthKeys
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            grid: {top: '55%'},
            series: [...netWorthChange.summaryKeys.map(key => {
                return {
                    name: key,
                    type: 'line',
                    data: netWorthChange.assetAnalysis.get(analysisType)!.get(AssetType.ASSET)!.get(key)!
                }
            }),
                ...netWorthChange.incomeKeys.map(key => {
                    return {
                        name: key,
                        type: 'line',
                        data: netWorthChange.assetAnalysis.get(analysisType)!.get(AssetType.INCOME)!.get(key)!
                    }
                }),
                ...netWorthChange.expenseKeys.map(key => {
                    return {
                        name: key,
                        type: 'line',
                        data: netWorthChange.assetAnalysis.get(analysisType)!.get(AssetType.EXPENSE)!.get(key)!
                    }
                }),
                ...buildPieGraphOption(netWorthChange, analysisType, 0).series
            ]
        })
    }

    const dispatch = useDispatch()
    console.log('currentInput', netWorthInput)
    console.log(dispatch)
    useEffect(() => {
        let params = searchParams.get("params")
        if (params) {
            let input = JSON.parse(params)
            updateNetWorthInput(input);
        } else {
            setSearchParams({"params": JSON.stringify(netWorthInput)})
        }

        dispatch({
            type: CALC_NET_WORTH
        })
    }, []);
    const updateValue = (key: string, value: any) => {
        let params: string = searchParams.get("params")!
        let newParams = {
            ...JSON.parse(params),
            [key]: value
        }
        setSearchParams({"params": JSON.stringify(newParams)})
        updateNetWorthInput(newParams)
    }
    const updateNetWorthInput = (netWorthInput: NetWorthInput) => {
        dispatch({
            type: CHANGE_NET_WORTH_INPUT,
            netWorthInput: netWorthInput
        })
    }

    const onFinish = (values: any) => {
        console.log('Received values of form:', values);
    };
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Net Worth Calculator</title>
            </Helmet>
            {MenuView('netWorthCalc')}
            {/*{JSON.stringify(netWorthCalculator)}*/}
            <div id="main" style={{width: '100%', height: 800}}></div>

            {/*<Form form={form} name="netWorthCalc" onFinish={onFinish} autoComplete="off" onValuesChange={(e) => {*/}
            {/*    console.log("from onValuesChange", e)*/}
            {/*}}>*/}
            {/*    <Form.Item>*/}
            {/*        <Button type="primary" htmlType="submit">*/}
            {/*            Submit*/}
            {/*        </Button>*/}
            {/*    </Form.Item>*/}
            {/*    <Form.Item*/}
            {/*        name="incomeItem"*/}
            {/*        label="Income"*/}

            {/*    >*/}
            {/*        <Form.List name="incomeList">*/}
            {/*            {(fields: FormListFieldData[], {add, remove}) => (*/}
            {/*                <>*/}
            {/*                    {fields.map(({key, name, ...restField}) => {*/}
            {/*                        return <>*/}
            {/*                            <Form.Item*/}
            {/*                                name={[key]}*/}
            {/*                            >*/}
            {/*                                {JSON.stringify(key)}*/}
            {/*                                {JSON.stringify(name)}*/}
            {/*                                {JSON.stringify(fields[key])}*/}
            {/*                                {*/}
            {/*                                    MoneyChange(form.getFieldValue(['incomeList', key]), (moneyChangeData: MoneyChangeData) => {*/}
            {/*                                        // @ts-ignore*/}
            {/*                                        // fields[key].moneyChangeData = moneyChangeData*/}

            {/*                                        form.setFieldValue(['incomeList', key], moneyChangeData)*/}
            {/*                                        console.log('denbig', moneyChangeData, form.getFieldValue(['incomeList', key]))*/}
            {/*                                        // this.forceUpdate()*/}
            {/*                                        // updateValue('incomeList', [moneyChangeData])*/}
            {/*                                    }, 0)}*/}
            {/*                            </Form.Item>*/}
            {/*                            <MinusCircleOutlined onClick={() => remove(name)}/>*/}
            {/*                        </>*/}

            {/*                    })}*/}
            {/*                    <Form.Item>*/}
            {/*                        <Button type="dashed" onClick={() => {*/}
            {/*                            let moneyChangeData = initMoneyChangeData()*/}

            {/*                            add(*/}
            {/*                                moneyChangeData*/}
            {/*                            )*/}
            {/*                        }} block icon={<PlusOutlined/>}>*/}
            {/*                            Add field*/}
            {/*                        </Button>*/}
            {/*                    </Form.Item>*/}
            {/*                </>*/}
            {/*            )}*/}
            {/*        </Form.List>*/}
            {/*    </Form.Item>*/}

            {/*    <Form.Item>*/}
            {/*        <Button type="primary" htmlType="submit">*/}
            {/*            Submit*/}
            {/*        </Button>*/}
            {/*    </Form.Item>*/}
            {/*</Form>*/}


            {/*<Space direction="vertical">*/}
            {/*    <DatePicker onChange={(e) => {*/}
            {/*        console.log(e)*/}
            {/*    }} picker="month"/>*/}
            {/*</Space>*/}
            {/*{MoneyChange(netWorthInput.incomeList[0], (moneyChangeData: MoneyChangeData) => {*/}
            {/*    updateValue('incomeList', [moneyChangeData])*/}
            {/*}, 0)}*/}
            {/*{MoneyNumber("netWorthGoal", netWorthInput.netWorthGoal!, (money: Money) => {*/}
            {/*    console.log('trigger back', money)*/}
            {/*    updateValue('netWorthGoal', money)*/}
            {/*})}*/}
            {/*<Input placeholder="Basic usage" value={netWorthInput.currentMonth} onChange={(e) => {*/}
            {/*    updateValue('currentMonth', e.target.value)*/}
            {/*}}/>*/}

        </div>
    )
};
