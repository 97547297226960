import {CN_TAX_RATE_CONFIG, SG_TAX_RATE_CONFIG, TaxRateConfig, US_TAX_RATE_CONFIG} from "./Tax";

export class Country {
    code!: string;
    currency!: CurrencyEnum;
    taxRateConfig!: TaxRateConfig;
    publicHolidays!: number;
    workDaysPerWeek!: number;
    workHours!: number;
    annualVacation!: number;
}

export enum CountryEnum {
    CN = "CN",
    SG = "SG",
    US = "US",
}

export enum CurrencyEnum {
    CNY = "CNY",
    USD = "USD",
    SGD = "SGD"
}

let CountryConfig = new Map<CountryEnum, Country>(
    [
        [CountryEnum.CN, {
            code: 'cn', currency: CurrencyEnum.CNY, taxRateConfig: CN_TAX_RATE_CONFIG,
            publicHolidays: 11, workDaysPerWeek: 5, workHours: 8, annualVacation: 10
        } as Country],
        [CountryEnum.SG, {
            code: 'sg', currency: CurrencyEnum.SGD, taxRateConfig: SG_TAX_RATE_CONFIG,
            publicHolidays: 11, workDaysPerWeek: 5, workHours: 8, annualVacation: 18
        } as Country],
        [CountryEnum.US, {
            code: 'us', currency: CurrencyEnum.USD, taxRateConfig: US_TAX_RATE_CONFIG,
            publicHolidays: 10.25, workDaysPerWeek: 5, workHours: 8, annualVacation: 13
        } as Country],
    ]
)

export {CountryConfig}
