export class TaxRate {
    money!: number;
    rate!: number;
}

export class TaxRateConfig {
    taxRateList!: TaxRate[];
    taxFreeAmount!: number;
    taxLink?: string;
}


// 中国还有6W的免征收
let CN_TAX_RATE: TaxRate[] = [
    {
        money: 36000, rate: 0.03
    }, {
        money: 144000, rate: 0.1
    }, {
        money: 300000, rate: 0.2
    }, {
        money: 420000, rate: 0.25
    }, {
        money: 660000, rate: 0.3
    }, {
        money: 960000, rate: 0.35
    }, {
        money: Number.MAX_SAFE_INTEGER, rate: 0.45
    },
];

let SG_TAX_RATE: TaxRate[] = [
    {
        money: 20000, rate: 0
    }, {
        money: 30000, rate: 0.02
    }, {
        money: 40000, rate: 0.035
    }, {
        money: 80000, rate: 0.07
    }, {
        money: 120000, rate: 0.115
    }, {
        money: 160000, rate: 0.15
    }, {
        money: 200000, rate: 0.18
    }, {
        money: 240000, rate: 0.19
    }, {
        money: 280000, rate: 0.195
    }, {
        money: 320000, rate: 0.20
    }, {
        money: Number.MAX_SAFE_INTEGER, rate: 0.22
    },
];

let US_TAX_RATE: TaxRate[] = [
    {
        money: 10275, rate: 0.10
    }, {
        money: 41775, rate: 0.12
    }, {
        money: 89075, rate: 0.22
    }, {
        money: 170050, rate: 0.24
    }, {
        money: 215970, rate: 0.32
    }, {
        money: 539900, rate: 0.35
    }, {
        money: Number.MAX_SAFE_INTEGER, rate: 0.37
    },
];
let CN_TAX_RATE_CONFIG: TaxRateConfig = {
    taxRateList: CN_TAX_RATE,
    taxFreeAmount: 60000,
    taxLink: 'http://www.chinatax.gov.cn/n810341/n810755/c3967308/content.html'
}

let SG_TAX_RATE_CONFIG: TaxRateConfig = {
    taxRateList: SG_TAX_RATE,
    taxFreeAmount: 0,
    taxLink: 'https://www.iras.gov.sg/taxes/individual-income-tax/basics-of-individual-income-tax/tax-residency-and-tax-rates/individual-income-tax-rates'
}

let US_TAX_RATE_CONFIG: TaxRateConfig = {
    taxRateList: US_TAX_RATE,
    taxFreeAmount: 0
}
export {CN_TAX_RATE_CONFIG, SG_TAX_RATE_CONFIG, US_TAX_RATE_CONFIG}