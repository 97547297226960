import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Card, Col, PageHeader, Radio, Row, Select, Space, Tag} from "antd";
import {useTranslation} from "react-i18next";
import "antd/dist/antd.css";
import "flag-icons/css/flag-icons.min.css";
import {CurrencyEnum} from "../common/Country";
import {IncomeTaxView} from "../components/IncomeTaxView";
import {IncomeTaxCompareView} from "../components/IncomeTaxCompareView";
import {changeCompareCurrency, updateCompareModel} from "../actions/action";
import {ApplicationState} from "../reducers/reducer";
import {Helmet} from "react-helmet";
import {CompareModel} from "../components/CompareNumber";
import {useCookies} from "react-cookie";

// import Cookies from 'universal-cookie';


export function TaxCalcView() {
    const state: any = useSelector((state: ApplicationState) => state)
    const dispatch = useDispatch()
    // let [locale, setLocale] = useState({})
    const [cookies, setCookie, removeCookie] = useCookies(['locale-cookie']);
    const {t, i18n} = useTranslation();
    useEffect(() => {
        let locale = cookies["locale-cookie"]
        if (locale == null) {
            changeLocale('en')
        } else {
            i18n.changeLanguage(locale);
        }
    }, []);

    function changeLocale(lng?: string) {
        if (cookies["locale-cookie"] == lng) {
            return
        }
        console.log('change local', cookies["locale-cookie"])
        // setLocale(locale)
        setCookie("locale-cookie", lng)
        i18n.changeLanguage(lng);
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t('TaxCalculator')}</title>
            </Helmet>

            <PageHeader
                title={t('TaxCalculator')}
                className="site-page-header"
                // subTitle={t('TaxCalculatorDesc')}
                tags={<Tag color="yellow">Beta</Tag>}
                // avatar={{src: 'https://avatars1.githubusercontent.com/u/8186664?s=460&v=4'}}
            >
                <div>
                    <div className="change-locale">
                        <Radio.Group defaultValue={cookies["locale-cookie"]}
                                     onChange={e => changeLocale(e.target.value)}>
                            <Radio.Button key="en" value={'en'}>English</Radio.Button>
                            <Radio.Button key="cn" value={'cn'}>中文</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
            </PageHeader>
            <Row justify="start">
                <Col>{IncomeTaxView(0)}</Col>
                <Col>{IncomeTaxView(1)}</Col>
            </Row>
            <Row justify="start">
                <Col>
                    <Card>
                        <Space size={"small"} direction={"vertical"}>
                            <div>
                                <span>{t("Compare Currency")}: </span> <Select
                                defaultValue={CurrencyEnum.CNY}
                                value={state.incomeTaxComparison.compareCurrency}
                                style={{
                                    width: 150,
                                }}
                                onChange={(e) => {
                                    dispatch(changeCompareCurrency(e))
                                }}
                            >
                                {Object.keys(CurrencyEnum).map(
                                    (key) => {
                                        return <Select.Option value={key}>{t(key)}</Select.Option>
                                    }
                                )}
                            </Select>
                            </div>
                            <div>
                                <span>{t("Compare Model")}: </span> <Select
                                defaultValue={CompareModel.ABSOLUTE}
                                value={state.incomeTaxComparison.compareModel}
                                style={{
                                    width: 150,
                                }}
                                onChange={(e) => {
                                    dispatch(updateCompareModel(e))
                                }}
                            >
                                {Object.keys(CompareModel).map(
                                    (key) => {
                                        return <Select.Option value={key}>{t(key)}</Select.Option>
                                    }
                                )}
                            </Select>
                            </div>
                        </Space>

                    </Card>

                </Col>
            </Row>
            <Row justify="start">
                <Col>{IncomeTaxCompareView(0)}</Col>
                <Col>{IncomeTaxCompareView(1)}</Col>
                {/*<Col>{IncomeTaxCompareView(2)}</Col>*/}
                {/*<Col>{IncomeTaxCompareView(3)}</Col>*/}
            </Row>
        </div>
    );
}